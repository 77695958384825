<template>
  <div>
    <form action="" @submit.prevent="onSubmit" class="d-flex">
      <CInput
        class="mb-0 mr-1"
        v-model="quantity"
        type="number"
        min="0"
        :append="suffix()"
        :step="step()"
        @update:value="onChange"
      />
      <CButton color="primary" type="submit" class="mr-1">
        <font-awesome-icon icon="sync" />
      </CButton>
      <CButton
        color="danger"
        variant="outline"
        type="button"
        @click.prevent="onRemove"
      >
        <font-awesome-icon icon="trash-alt" />
      </CButton>
    </form>

    <CModal
      :title="$t('user.partials.marketCart.removeModal')"
      size="lg"
      :centered="true"
      :show.sync="showModal"
    >
      <div>
        <h4>{{ getProductName() }} - {{ getVariantName() }}</h4>
        {{ $t("family.market.cart.remove.text") }}
      </div>
      <template #footer>
        <CButton
          color="primary"
          variant="outline"
          @click.prevent="showModal = false"
        >
          {{ $t("common.editor.button.cancel") }}
        </CButton>
        <CButton color="primary" @click.prevent="onConfirm()">
          {{ $t("common.editor.button.confirm") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import get from "lodash/get";
import { getOptionLabel, getTranslation } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
export default {
  name: "MarketCartTableVariantQuantity",

  props: {
    marketId: { type: Number },
  },

  data() {
    return {
      data: {},
      showModal: false,
      quantity: 0,
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },

    familyId() {
      return this.$store.state.connections.current.family.id;
    },

    productId() {
      return get(this.data, "view.mp.ppl.productproducer.id");
    },
  },

  mounted() {
    this.quantity = this.data.qta;
  },

  methods: {
    onChange(value) {
      const payload = {
        row: this.data,
        data: { marketId: this.marketId, quantity: value },
      };
      this.$root.$emit("onCartItemChangeQuantity", payload);
    },

    onSubmit() {
      const payload = {
        row: this.data,
        data: { marketId: this.marketId, quantity: this.quantity },
      };
      this.$root.$emit("onCartItemUpdate", payload);
    },

    onRemove() {
      this.showModal = true;
    },

    getProductName() {
      const translations = get(
        this.data,
        "view.mp.ppl.productproducer.translations"
      );
      return getTranslation(translations, this.lang);
    },

    getVariantName() {
      const translations = get(this.data, "view.mp.ppl.translations");
      return getTranslation(translations, this.lang);
    },

    suffix() {
      const pp = get(this.data, "view.mp.ppl.productproducer");
      const pw_option = get(pp, "extra.pw_option", 1);
      return pw_option === 3
        ? `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`
        : `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
    },

    step() {
      const isWeightProduct =
        get(this.data, "view.mp.ppl.productproducer.pw") === true;
      return isWeightProduct ? "0.1" : "1";
    },

    onCancel() {
      this.showModal = false;
    },

    onConfirm() {
      const payload = {
        row: this.data,
        data: { marketId: this.marketId, quantity: 0 },
      };
      this.$root.$emit("onCartItemRemove", payload);
      this.showModal = false;
    },
  },
};
</script>
